import { Button, Container, ListGroup } from "react-bootstrap";
import React, { Component } from "react";

/** Wraps a ListGroup.Item so that we can use this.props.saveID */
export class LoadRow extends Component {
  styleVar = {};

  onLoad = () => {
    this.props.loadCallback(this.props.saveID);
  };

  onDelete = () => {
    this.props.deleteCallBack(this.props.saveID);
    //this.styleVar = { display: "none" };
  };

  render() {
    return (
      <>
        <Container style={this.styleVar} fluid>
          <ListGroup.Item
            title={this.props.saveInfo}
            action
            onClick={this.onLoad}
            style={{ width: "95%", marginTop: 5, display: "inline" }}
          >
            {this.props.text}
          </ListGroup.Item>
          <Button
            className="btn btn-success"
            style={{ width: 35, float: "right", marginTop: 10, display: "inline" }}
            variant="danger"
            onClick={this.onDelete}
          >
            X
          </Button>
        </Container>
      </>
    );
  }
}
