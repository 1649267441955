import "bootstrap/dist/css/bootstrap.min.css";

import React, { useReducer, useState, useRef } from "react";
import { Row, Col, ListGroup, Button, Form, Image, Container } from "react-bootstrap";
import { configure, HotKeys, withHotKeys } from "react-hotkeys";
import Modal from "react-bootstrap/Modal";
import { withCookies } from "react-cookie";

import Axios from "axios";
import { LoadRow } from "./comms/LoadRow";
import { DateTime } from "luxon";
import { appConfig } from "./settings/Settings";

configure({
	ignoreTags: ["input", "select"],
	ignoreEventsCondition: function () {},
});

//Mirrors kromyre.presentation.presentables.picture.layout.PictureModificationTypesx.
const PM_SCALE = 1;
const PM_OPACITY = 2;
const PM_BLUR = 3;

const Choices = (props) => {
	let listItems = props.choices.map(function (choice, index) {
		//console.log("Processing choice " + index);
		return (
			<ListGroup.Item
				action
				href={"#c" + index}
				key={props.dialogID + "." + index}
				//TODO: Report a bug to React Bootstramp :) because when setting this simply to Index, onSelect first choice comes wrong.
				eventKey={index + 1 + "|" + choice}
				onSelect={props.onChoiceSelected}
				active={false}>
				{index + 1}). {choice}
			</ListGroup.Item>
		);
	});

	return <ListGroup key={props.dialogID}>{listItems}</ListGroup>;
};

function App(props) {
	const { cookies } = props;

	//IMPORTANT:
	//AOMG: TO DEBUG, PLEASE LAUNCH CHROME WITH --remote-debugging-port=9222 and use our launch.json / Attach to Chrome.

	//Choices ref for keeping track of choices.
	const totalChoices = useRef(0);
	//Dialog Textbox ref for re-focusing.
	const dialogTextComponentRef = useRef(null);
	const saveNameRef = useRef(null);

	//Modal Load Dialog.
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const onShowLoadDialog = () => setShowModal(true);

	//Currently displayed dialog.
	const [dialog, setDialog] = useState({
		displayText: "", //Populated via data in texts.
		texts: {},
		choices: [], //Populated via data in choicesGrid.
		choicesGrid: {},
		dialogID: "",
		pictures: {},
	});

	/** Nexus Configuration currently being used. */
	const [configID, setConfigID] = useState(cookies.get("configID") || "0");

	/** Nexus Seed currently being used. */
	const [seedString, setSeedString] = useState(cookies.get("seedString") || "Arise");

	/** SaveGame data. */
	const [shallowSavedExperienceName, setShallowSavedExperienceName] = useState("");

	/** User ID. */
	const [userID, setUserID] = useState(cookies.get("userID") || "MANDATORY");

	/** Keep track of all Choices made so far. */
	const [choicesSoFar, setChoicesSoFar] = useState([]);

	/** Number of turns behind the current Save to Load from.
      If there's a bug, this is used to resume from just before the bug with X number of turns. */
	const [rewindChoices, setrewindChoices] = useState(0);

	/** A StoryTeller script to run. */
	const [storyTellerScriptID, setStoryTellerScriptID] = useState(cookies.get("storyTellerScriptID") || "ax0");

	/** If the Proceed button is disabled. It always is at first. Until a Choice is selected. */
	const [isProceedBtnDisabled, setIsProceedBtnDisabled] = useState(true);

	/** Forces a refresh of the page because totalSaves is mentioned in useEffect. */
	const [totalSaves, setTotalSaves] = useState(0);

	//Currently selected Choice in the List. This is used ONLY for ensuring that we
	//have preserved the selected Choice when causing a re-render due to enabling the Proceed Button.
	const [selectedChoice, setSelectedChoice] = useState(0);
	//Currently selected choice. A Reducer will be used to keep track of the key.
	const [choiceState, dispatchChoiceStateChange] = useReducer(choiceReducer, {
		choiceKey: -2,
	});

	const [pictureURLs, setPictureURLs] = useState([]);
	const [pictureStyles, setPictureStyles] = useState([]);

	//State for Aspects received from Indra.
	const [aspects, setAspects] = useState([]);

	//Index of currently processed aspect.
	const [currentAspectIndex, setCurrentAspectIndex] = useState(0);

	//Cut-Scene Mode.
	const [cutSceneMode, setCutSceneMode] = useState(false);

	//In Cut-scene mode, display the Choice at next invocation of processAspect.
	const [displayChoiceNext, setDisplayChoiceNext] = useState(false);

	//Aspects received from Indra. Used when processing them immediately.
	let _aspectsArray = null;

	/**
	 * Refreshes Choice State based on action.
	 * @param {object} state Choice State.
	 * @param {object} action Describes how to change the State.
	 */
	function choiceReducer(state, action) {
		switch (action.type) {
			case "choice":
				return { choiceKey: action.choiceKey };
			default:
				throw new Error();
		}
	}

	/** Processes a Nexus Text Grid. */
	function processTextGrid(textGrid, textsGridScenery = undefined) {
		let ret = "";
		//Go through the textsGrid map. Each key is a Category (for a Tile, categories are: "Artifacts" "Characters", "Main", "Routes")
		Object.keys(textGrid).forEach((key) => {
			let textsPerCategory = textGrid[key];
			//Now check if this Category has any items in it.
			if (!!Object.keys(textsPerCategory).length) {
				//Uncomment to DEBUG LAYOUT:
				//console.log("processTextGrid @ key: " + key);
				//console.log(Object.keys(textsPerCategory));

				var textFound = false; //TRUE: at least ONE text was found for this key.
				//Go through each individual object in a Category (these are various HasPresenterBaseI).
				Object.keys(textsPerCategory).forEach((subKey) => {
					let textsPerObject = textsPerCategory[subKey];
					//Now go through each text in that object (these are Presenters).
					Object.keys(textsPerObject).forEach((subSubKey) => {
						//This sort of Presenter Data will NOT be displayed in the text, but rather processed by an AI Engine.
						if (subSubKey === "AI-Picture") {
							console.dir("REQUEST FOR AI-generated PICTURE: " + textsPerObject[subSubKey]);
						}
						//This client will ignore Character names when Selecting Characters.
						else if (key === "Characters" && subSubKey === "Name") {
							//
						}
						//This client will ignore Character names showing up in DialogMain.
						else if (key === "DialogMain" && subSubKey === "Name") {
							//
						}
						//This client will also ignore Character Selection.
						else if (
							key === "Main" &&
							textsPerCategory["Settings Selection Dialog"] != null &&
							//Ignore all data that is not in the "Settings Selection Dialog", and also ignore all Keys that are not "Text".
							(subKey !== "Settings Selection Dialog" || subSubKey !== "Text")
						) {
							//
						}
						//All other subSubKeys
						else {
							if (textsPerObject[subSubKey].length === 0) return;
							textFound = true;
							//console.log("This text: '" + textsPerObject[subSubKey] + "'");
							ret += textsPerObject[subSubKey]; //DEBUG LAOYUT: + " /// " + subKey.
							//Separate some text with various delimiters.
							if (key === "Routes") ret += " ";
							if (key === "Characters") ret += "\n\n";
						}
					});
				});

				//console.log("Text found for " + key + "? -> " + textFound);

				//ONLY if a text was found above, we will add new lines for readability.
				if (textFound) ret += ret.length > 0 ? "\n\n" : ""; //DEBUG LAYOUT: "+++\n\n" : "---" + key; //HINT: To also display Category Name, add "key".
			}

			//After the Main key, insert the Scenery Presentables, if any, reusing this same function,
			//because the Scenery has the same structure.
			if (key === "TileMain" && textsGridScenery !== undefined) {
				Object.keys(textsGridScenery).forEach((key) => {
					ret += processTextGrid(textsGridScenery[key]);
				});
			}
		});
		if (ret.endsWith("\n\n") && textsGridScenery !== undefined) ret = ret.substring(0, ret.length - 2);
		return ret;
	}

	function processChoicesGrid(choicesGrid) {
		let ret = [];
		console.dir("Now processing Choices...");
		//Go through the choicesGrid map. Each key is a Category (for a Tile, categories are: "Artifacts" "Characters", "Main", "Routes")
		Object.keys(choicesGrid).forEach((key) => {
			let choicesArray = choicesGrid[key];
			console.log("Listing choices for key: " + key);
			console.dir(choicesArray);
			//If we have some Choices, add them all to the Dialog's choices.
			//NOWS: implement proper support for Cut-scenes, by correctly handling Choices.
			if (choicesArray.length > 0) ret.push(...choicesArray.map((choice) => choice.text));
		});
		return ret;
	}

	function processPictureGrid(picturesGrid, cutSceneMode = false, useChooser = false) {
		//Go through the picturesGrid map. Each key is a Category (for a Tile, categories are: "Artifacts" "Characters", "Main", "Routes")
		Object.keys(picturesGrid).forEach((key) => {
			let picturesPerCategory = picturesGrid[key];
			//console.log("Pictures Processing: " + key);
			//Now check if this Category has any items in it.
			if (!!Object.keys(picturesPerCategory).length) {
				//Go through each individual object in a Category (these are various HasPresenterBaseI).
				Object.keys(picturesPerCategory).forEach((subKey) => {
					//console.log("PCT: - " + subKey);
					let picturesPerObject = picturesPerCategory[subKey];
					//Now go through each text in that object (these are Presenters).
					Object.keys(picturesPerObject).forEach((subSubKey) => {
						//console.log("PCT: - - " + subSubKey);
						let pictureData = picturesPerObject[subSubKey];
						//Apply data.
						//Background uses only 1 picture.
						if (subSubKey === "Background") {
							applyToPicture(pictureData, 0);
						}
						//Face uses 2 pictures, based on who's who.
						if (subSubKey === "Face") {
							//Non Cut-scene mode: set both Speaker/Chooser pictures.
							if (!cutSceneMode) {
								//console.error("Picture setup in standard mode!");
								if (subKey === "DialogSpeaker") applyToPicture(pictureData, 0);
								else if (subKey === "DialogChooser") applyToPicture(pictureData, 1);
							}
							//Cut-scene mode: set only the Speaker/Chooser picture that is relevant, and only use the first picture.
							else {
								//console.warn("Picture setup in Cut-scene mode!");
								if (subKey === "DialogSpeaker" && !useChooser) applyToPicture(pictureData, 0);
								if (subKey === "DialogChooser" && useChooser) applyToPicture(pictureData, 0);
							}
						}
					});
				});
			}
			//If we have some Texts, add them all to the Dialog's choices.
			//if (choicesArray.length > 0) dialog.choices.push(...choicesArray);
		});
	}

	//Population of Dialog. Refreshed when the Chooser makes a choice.
	React.useEffect(() => {
		async function startOrRefresh() {
			let cKey = choiceState.choiceKey;
			if (cKey === -2) {
				console.log("Choice is -2. Perhaps this is a First Run.");
				await Axios.get(appConfig.BACKEND_URL + "login/" + userID + "/" + configID + "/" + seedString).then((res) => {});
				cKey = -1;
			}
			console.log("Applying Choice: " + cKey);
			Axios.get(appConfig.BACKEND_URL + "choice/" + userID + "/" + cKey)
				.then((res) => {
					let aspectsArray = JSON.parse(res.data);
					console.log("===============================");
					console.log("Got Aspect Array:");
					console.dir(aspectsArray);
					_aspectsArray = aspectsArray;
					setAspects(aspectsArray);
					showAspect(true);
				})
				.catch((e) => {
					console.warn("Axios couldn't do it man... " + e);
				});
		}
		startOrRefresh();
	}, [choiceState]);

	/** Processes Aspects received from Indra, one by one, with each call. */
	async function showAspect(firstCall = false) {
		//Because this is called in useEffect and setState is a queue, we may get Aspects from the _aspectsArray variable instead.
		const anyAspectsArray = _aspectsArray !== null && _aspectsArray.length > 0 ? _aspectsArray : aspects;

		//Update current Aspect Index at First Call.
		if (firstCall) {
			setCurrentAspectIndex(0);
			//If we have more than one Aspect, remember that we're in Cut-Scene Mode.
			setCutSceneMode(anyAspectsArray.length > 1);
			setAspects(anyAspectsArray);
			setDisplayChoiceNext(true);
		}

		let aspect = firstCall ? _aspectsArray[currentAspectIndex] : anyAspectsArray[currentAspectIndex];
		console.log("displayChoiceNext: " + displayChoiceNext + " firstCall: " + firstCall + " cutSceneMode: " + cutSceneMode);
		console.log("Arrays length: " + (_aspectsArray != null ? _aspectsArray.length : "NULL") + " " + aspects.length);

		//This may be NULL when there is no valid response from the server.
		if (aspect == null) {
			console.error("Aspect is NULL. This usually indicates a design flaw.");
			return;
		}

		//Typical way of displaying an Aspect.
		if (!displayChoiceNext || firstCall) {
			console.log("===== Proceeding with Aspect #" + currentAspectIndex);
			console.dir(aspect);
			console.dir(anyAspectsArray.length);

			//PRESERVE Dialog Text from before.
			aspect.displayText = dialog.displayText;

			//Text.
			aspect.displayText += processTextGrid(aspect.textsGrid, aspect.textsGridScenery);
			//Space out separate fragments in the same Dialog.
			aspect.displayText += "\r---------------------------------\r";

			//If we reached the last Aspect, we're no longer in Cut-scene mode.
			const endedCutScene = currentAspectIndex === anyAspectsArray.length - 1;

			//Only set Choices if we're not in Cut-scene mode.
			//if (!cutSceneMode || endedCutScene)
			aspect.choices = processChoicesGrid(aspect.choicesGrid);
			//Update number of Choices.
			totalChoices.current = aspect.choices.length;

			//Process Pictures.
			processPictureGrid(aspect.picturesGrid, true, false);

			//HACK: TO BE REMOVED.
			aspect.backgroundPictureWidth = 250 * 1.2;

			setDialog({
				displayText: aspect.displayText, //Populated via data in texts.
				texts: aspect.texts,
				choices: anyAspectsArray.length > 1 && !endedCutScene ? ["Continue..."] : aspect.choices, //Populated via data in choicesGrid.
				choicesGrid: aspect.choicesGrid,
				dialogID: aspect.dialogID,
			}); //Set the dialog now that data has been processed.

			//If the Choice text is [[Continue Cut-scene]], it means it's from a Cut-scene Template.
			//In this case, we will NOT display the Choice, but rather the next Aspect.
			if (aspect.choices[0] === "[[Continue Cut-scene]]") {
				console.warn("Aspect displaying in Cut-scene TEMPLATE mode!");
				setDisplayChoiceNext(false);
				setCurrentAspectIndex(currentAspectIndex + 1);
			}
			//Detailed Cut-scene mode, where we also display Choices.
			else {
				//console.log("Aspect displaying in NORMAL mode!");
				setDisplayChoiceNext(true);
				//console.error("We just changed displayChoiceNext to true.");
			}

			if (endedCutScene) {
				//Reset Aspect-related variables.
				_aspectsArray = null;
				setAspects([]);
				setCutSceneMode(false);
				setDisplayChoiceNext(false);
				setCurrentAspectIndex(0);
			}

			await focusRefocus();
		}
		//In Cut-Scene mode, display the Choice at every other invocation of processAspect, because Choices are not really Choices,
		//but rather fragments in the Cut-scene flow.
		else {
			console.log("===== Cut-scene mode now at the phase of DISPLAYING CHOICE. Current Aspect:");
			console.dir(aspect);

			//Setup Pictures.
			processPictureGrid(aspect.picturesGrid, true, true);

			//Add the first Choice (which is the only one in Cut-scene mode) to the display text of the Aspect,
			//which will then show up in the Dialog.
			aspect.displayText += processChoicesGrid(aspect.choicesGrid)[0];
			//Space out separate fragments in the same Dialog.
			aspect.displayText += "\r---------------------------------\r";

			setDialog({
				displayText: aspect.displayText, //Populated via data in texts.
				texts: aspect.texts,
				choices: cutSceneMode ? ["Continue..."] : aspect.choices, //Populated via data in choicesGrid.
				choicesGrid: aspect.choicesGrid,
				dialogID: aspect.dialogID,
			});

			//Next time, move to the next Aspect.
			setCurrentAspectIndex(currentAspectIndex + 1);
			setDisplayChoiceNext(false);
			//console.error("We just changed displayChoiceNext to false.");
		}

		dialogTextComponentRef.current.scrollTop = dialogTextComponentRef.current.scrollHeight;

		//console.log("Current Dialog State:");
		//console.dir(dialog);
	}

	//Population of Dialog. Refreshed when the Chooser makes a choice.
	React.useEffect(() => {
		console.log("Total Saves Modified: " + totalSaves);
	}, [totalSaves]);

	/** Sometiimes we Need to focus/refocus because of bugs in the Hotkeys component which prevent setState from working properly. */
	async function focusRefocus() {
		saveNameRef.current.focus();
		dialogTextComponentRef.current.focus();
		await sleep(10);
	}

	function sleep(ms) {
		//console.log("Sleeping...");
		return new Promise((resolve) => {
			setTimeout(resolve, ms);
		});
	}

	/**
	 * Applies PictureData to one of the Picture arrays, which in turn control how the pictures look.
	 * @param {PictureData} pictureData Picture Data containing all info necessary to set up the Picture.
	 * @param {Int} id Picture Array element ID. There are 2 pictures, so this is either 0 or 1.
	 */
	function applyToPicture(pictureData, id) {
		let style = {};
		if (pictureData.modifications)
			pictureData.modifications.forEach((modification) => {
				console.log("Applying modificationType " + modification.modificationType);
				switch (modification.modificationType) {
					case PM_SCALE:
						//TBD.
						break;
					case PM_OPACITY:
						console.log("Applying opacity.");
						style.opacity = modification.opacity;
						break;
					case PM_BLUR:
						console.log("Applying blur.");
						style.filter = "blur(" + modification.blurAmount + "px)";
						break;
					default:
						break;
				}
			});

		//console.dir("Style to be set: ");
		//console.dir(style);

		//Insert or Replace array elements to apply changes.
		//Insert mode:
		if (
			id !== 0 && //Only for 2nd picture.
			//NOWS: added this check because of the ANNOYING state issue described below @ load, when we can't clear picture URLs.
			pictureURLs.indexOf(pictureData.url) === -1
		) {
			console.error("Adding NEWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW");
			pictureURLs.push(pictureData.url);
		} else pictureURLs.splice(id, 1, pictureData.url);

		if (id <= pictureStyles.length) pictureStyles.push(style);
		else pictureStyles.splice(id, 1, style);

		//Apply changes.
		setPictureURLs(pictureURLs);
		setPictureStyles(pictureStyles);

		console.log("Pictures length: " + pictureURLs.length);
	}

	/**
	 * Updates selectedChoice with the currently selected choice in the list.
	 * @param {int} choiceKey Selected choice key.
	 */
	function choiceListSelectionMade(choiceKey) {
		let newChoice = Number.parseInt(choiceKey.substr(0, choiceKey.indexOf("|")));
		console.log("Selected Choice set to: " + newChoice);
		setIsProceedBtnDisabled(false);
		setSelectedChoice(newChoice);
	}

	//Called by Proceed button OR hotkeys.
	async function updateChoiceAndProceed(choiceKey) {
		//console.error("Aspects in state: " + aspects.length);
		//When in Cut-scene mode, just call processAspect, which will handle text/choice alternation.
		if (cutSceneMode || (_aspectsArray !== null && _aspectsArray.length > 1) || aspects.length > 1) {
			console.warn("We're in CUT-SCENE MODE, with displayChoiceNext: " + displayChoiceNext);
			await focusRefocus();
			showAspect();
			await focusRefocus();
		}
		//When NOT in Cut-scene mode, we will handle the Choice which will result in a request to Indra.
		else {
			console.warn("Standard Choice Mode.");
			//console.log("Choices count: " + totalChoices);
			if (choiceKey > totalChoices.current) {
				console.error("Invalid Choice Number:  " + choiceKey);
				return;
			}
			console.log("Executing Choice: " + choiceKey);

			//Memorize all Choices, for possible shallow-save/load.
			choicesSoFar.push(choiceKey);
			setChoicesSoFar(choicesSoFar);
			console.log("choicesSoFar: " + choicesSoFar);

			//Clear Dialog.
			setPictureURLs([]);
			setPictureStyles([]);
			setIsProceedBtnDisabled(true); //Disable the Proceed button for next time.

			await focusRefocus();
			//Apply new choice.
			dispatchChoiceStateChange({ type: "choice", choiceKey });
			await focusRefocus();
		}
	}

	//MAIN COMMANDS.

	//Proceed button.
	function proceedClick(e) {
		e.preventDefault();
		//Will use previously stored choice.
		console.log("Choosing Choice: " + selectedChoice);
		updateChoiceAndProceed(selectedChoice);
	}

	function onReboot(e) {
		e.preventDefault();
		console.log("Rebooting with configID: " + configID);
		Axios.get(appConfig.BACKEND_URL + "restart/" + userID + "/" + configID + "/" + seedString).then((res) => {});
		//Auto-reload to apply changes.
		setTimeout(() => {
			window.location.reload();
		}, 500);
	}

	function onRunStoryTellerScript(e) {
		e.preventDefault();
		Axios.get(appConfig.BACKEND_URL + "runStoryTellerScript/" + userID + "/" + storyTellerScriptID).then((res) => {});
	}

	//SAVE/LOAD.

	/** Returns saved Experiences. */
	function getSavedExperiences() {
		//Retrieve current Save Data.
		let saveData = window.localStorage.getItem("shallowSavedExperiences");
		//UNCOMMENT the line below to WIPE save data.
		//saveData = null;
		//Deserialize / Initialize Save Data if NULL.
		let allSavedExperiences = saveData == null ? [] : JSON.parse(saveData);

		//Must improve deserialization for particular objects which won't automatically map to their types.
		allSavedExperiences.forEach((save) => {
			//For example Luxon dates.
			save.saveDate = DateTime.fromISO(save.saveDate);
			save.lastAccessDate = DateTime.fromISO(save.lastAccessDate);
		});

		//Also sort on Last Access Date.
		allSavedExperiences.sort((a, b) => {
			return b.lastAccessDate.toMillis() - a.lastAccessDate.toMillis();
		});

		return allSavedExperiences;
	}

	function onRefresh(e) {
		e.preventDefault();
		//Will use previously stored choice.
		console.log("Setting Choice to -1");
		updateChoiceAndProceed(-1);
	}

	function onSave(e) {
		e.preventDefault();
		//console.log("Saving: " + shallowSavedExperienceName);
		//console.log("choicesSoFar: " + choicesSoFar);
		//console.log("configID: " + configID);
		if (!shallowSavedExperienceName) {
			alert("You must write a valid Experience Name.");
			return;
		}

		//Get all experiences first, so we can append to them.
		let allSavedExperiences = getSavedExperiences();
		//Add a key at the current Saved Experience name.
		allSavedExperiences.push({
			saveName: shallowSavedExperienceName,
			configID,
			choices: choicesSoFar,
			rewindChoices,
			saveDate: DateTime.now(),
			lastAccessDate: DateTime.now(),
			seedString,
		});

		console.log("Saving:");
		console.dir(allSavedExperiences);
		saveExperiences(allSavedExperiences);
	}

	function saveExperiences(allExperiences) {
		//Serialize & Save.
		window.localStorage.setItem("shallowSavedExperiences", JSON.stringify(allExperiences));
	}

	const loadID = (saveID) => {
		console.dir("Loading saveID: " + saveID);
		setShowModal(false);

		//Get Experience and update its Last Access Date, then save it. (Just to bring it on top of the list)
		const allSavedExperiences = getSavedExperiences();
		const savedExperience = allSavedExperiences[saveID];
		savedExperience.lastAccessDate = DateTime.now();
		saveExperiences(allSavedExperiences);

		//Clear Dialog.
		setPictureURLs([]);
		//Except that this ANNOYING CALL doesn't work, because setState is a queue.
		//SO when we run processChoicesGrid we end up with picture leftovers from any previous run.
		//To see this crash, comment the above line where pictureURLs.indexOf(pictureData.url)
		//Load a shallow save that starts from a Hub Tile and leads to the same Hub Tile.
		setPictureStyles([]);
		setIsProceedBtnDisabled(true); //Disable the Proceed button for next time.

		console.log("Loading Saved Experience:");
		console.dir(savedExperience);
		if (savedExperience.choices.length === 0) {
			//AOMG: instead, show a dialog in the client saying that the save is empty (no choices).
			savedExperience.choices.push(-1);
		}
		Axios.get(
			appConfig.BACKEND_URL +
				"shallow-load/" +
				userID +
				"/" +
				savedExperience.configID +
				"/" +
				savedExperience.choices +
				"/" +
				savedExperience.rewindChoices +
				"/" +
				savedExperience.seedString
		).then(async (res) => {
			//The answer from the Server is similar to that from a Choice, except that it also contains an allTexts member.
			let loadData = JSON.parse(res.data);
			console.log("Got Scenes:");
			console.dir(loadData);
			//Combine all text from all Scenes to restore text history of everything tha happened.
			let totalText = "";
			for (var i = 0; i < loadData.allTexts.length; i++)
				totalText += processTextGrid(loadData.allTexts[i], loadData.allTextsScenery[i]) + "\n\n";

			//Clear Dialog.
			setPictureURLs([]);
			//Except that this DUMB CALL doesn't work, because setState is a shitty queue.
			//So then it does NOT clean any shit, leaves shit all over the floor and when we run processChoicesGrid
			//we end up with shitty picture leftovers from any previous run.
			setPictureStyles([]);
			setIsProceedBtnDisabled(true); //Disable the Proceed button for next time.

			//Reset Aspect-related variables.
			_aspectsArray = null;
			setAspects([]);
			setCutSceneMode(false);
			setDisplayChoiceNext(false);
			setCurrentAspectIndex(0);

			saveNameRef.current.focus();
			dialogTextComponentRef.current.focus();
			await sleep(10);

			let aspects = loadData.aspects;
			//From the server, we get an Array of Aspects.
			console.log("===============================");
			console.log("Got Aspect Array:");
			console.dir(aspects);

			let j = 1;
			//Going through each of the Aspects.
			for (const aspect of aspects) {
				console.log("===== Proceeding with Aspect #" + j++);

				//Now apply the current Scene too.
				processPictureGrid(aspect.picturesGrid);

				let processedChoices = processChoicesGrid(aspect.choicesGrid);

				setDialog({
					displayText: totalText,
					choices: processedChoices,
					dialogID: aspect.dialogID,
				});

				//Update number of Choices.
				totalChoices.current = processedChoices.length;
			}

			//Set Choices to be precisely what we loaded so far.
			setChoicesSoFar(savedExperience.choices);
			dialogTextComponentRef.current.scrollTop = dialogTextComponentRef.current.scrollHeight;
		});
	};

	const deleteSaveID = (saveID) => {
		let allSavedExperiences = getSavedExperiences();
		allSavedExperiences.splice(saveID, 1);
		console.log("Deleted! " + saveID + ". Now dumping allSavedExperiences.");
		console.dir(allSavedExperiences);
		//Serialize & Save.
		window.localStorage.setItem("shallowSavedExperiences", JSON.stringify(allSavedExperiences));
		setTotalSaves(allSavedExperiences.length - 1);
	};

	//HOTKEYS.

	const keyMap = {
		CHOICE_1: "1",
		CHOICE_2: "2",
		CHOICE_3: "3",
		CHOICE_4: "4",
		CHOICE_5: "5",
		CHOICE_6: "6",
		CHOICE_7: "7",
		CHOICE_8: "8",
		CHOICE_9: "9",
		CHOICE_0: "0",
	};

	const handlers = {
		CHOICE_1: () => updateChoiceAndProceed(1),
		CHOICE_2: () => updateChoiceAndProceed(2),
		CHOICE_3: () => updateChoiceAndProceed(3),
		CHOICE_4: () => updateChoiceAndProceed(4),
		CHOICE_5: () => updateChoiceAndProceed(5),
		CHOICE_6: () => updateChoiceAndProceed(6),
		CHOICE_7: () => updateChoiceAndProceed(7),
		CHOICE_8: () => updateChoiceAndProceed(8),
		CHOICE_9: () => updateChoiceAndProceed(9),
		CHOICE_0: () => updateChoiceAndProceed(0),
	};

	//List of rows for the Load Modal dialog.
	let loadRows = [];
	let allSavedExperiences = getSavedExperiences();

	//Generate Load Entries.
	for (var i = 0; i < allSavedExperiences.length; i++) {
		const save = allSavedExperiences[i];
		//console.log("Rendering Load Entry for save:");
		//console.dir(save);
		loadRows.push(
			<LoadRow
				key={i}
				text={
					save.saveDate.toFormat("MM-dd @ HH:mm") +
					" --> " +
					save.saveName +
					" - [Cfg: " +
					save.configID +
					"] [Ch: " +
					save.choices.length.toString() +
					(save.rewindChoices === 0 ? "" : "-" + save.rewindChoices) +
					"] [S: " +
					save.seedString +
					"]"
				}
				saveID={i}
				saveInfo={
					"Save Date: " +
					save.saveDate.toFormat("yyyy-MM-dd @ HH:mm:ss") +
					"\rLast Access Date: " +
					save.lastAccessDate.toFormat("yyyy-MM-dd @ HH:mm:ss") +
					"\rConfig Number: " +
					save.configID +
					"\rThe Seed: " +
					save.seedString +
					"\rTotal Choices: " +
					save.choices.length +
					"\rRewind Choices: " +
					save.rewindChoices
				}
				loadCallback={loadID}
				deleteCallBack={deleteSaveID}></LoadRow>
		);
	}

	/*
  loadRows = (
    <>
      <LoadRow text="abc" saveID="def" loadCallback={loadID} deleteCallBack={deleteSaveID}></LoadRow>
      <LoadRow text="123" saveID="cah" loadCallback={loadID} deleteCallBack={deleteSaveID}></LoadRow>
    </>
  );
  */

	return (
		<>
			<Modal show={showModal} onHide={handleClose} dialogClassName="modal-90w">
				<Modal.Header closeButton>
					<Modal.Title>Load Experience</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ListGroup defaultActiveKey="#link1">{loadRows}</ListGroup>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>

			<HotKeys keyMap={keyMap} handlers={handlers}>
				<Form.Group controlId="main.dialog">
					<Container fluid>
						<Row>
							<Col sm={2}>
								<Image src={"/" + (pictureURLs[0] != null ? pictureURLs[0] : "")} style={pictureStyles[0]} width={"100%"}></Image>
								<br />
								<Image src={"/" + (pictureURLs[1] != null ? pictureURLs[1] : "")} style={pictureStyles[1]} width={"100%"}></Image>
							</Col>
							<Col xxl={8}>
								<Form.Control
									ref={dialogTextComponentRef}
									as="textarea"
									rows={12}
									value={dialog.displayText}
									readOnly></Form.Control>
							</Col>
						</Row>
					</Container>
				</Form.Group>
				<Row>
					<Col sm={10}>
						<Choices choices={dialog.choices} dialogID={dialog.dialogID} onChoiceSelected={choiceListSelectionMade}></Choices>
					</Col>
				</Row>
				<br />
			</HotKeys>
			<Form.Group controlId="main.navButtons">
				<Container fluid>
					<Button
						style={{ width: 200 }}
						variant="primary"
						onClick={proceedClick}
						//TODO: why doesn't this work simply by saying "selectedChoice === 0"?
						disabled={isProceedBtnDisabled}>
						Proceed
					</Button>
					&nbsp;&nbsp;&nbsp;
					<Button style={{ width: 200 }} variant="primary" onClick={onReboot}>
						Restart
					</Button>{" "}
					Config ID:
					<Form.Control
						value={configID}
						onChange={(e) => {
							const configID = e.target.value;
							setConfigID(configID);
							cookies.set("configID", configID, { path: "/", maxAge: 3600 * 24 * 360 });
						}}
						type="text"
						style={{ width: 50, display: "inline", marginLeft: "5px", paddingTop: "0px" }}
					/>{" "}
					The Seed:
					<Form.Control
						value={seedString}
						onChange={(e) => {
							const seed = e.target.value;
							setSeedString(seed);
							cookies.set("seedString", seed, { path: "/", maxAge: 3600 * 24 * 360 });
						}}
						type="text"
						style={{ width: 100, display: "inline", marginLeft: "5px", paddingTop: "0px" }}
					/>
					&nbsp;&nbsp;&nbsp;
					<Button style={{ width: 200 }} variant="primary" onClick={onRunStoryTellerScript}>
						Run StoryTeller Script
					</Button>{" "}
					Script ID:
					<Form.Control
						value={storyTellerScriptID}
						onChange={(e) => {
							const storyTellerScriptID = e.target.value;
							setStoryTellerScriptID(storyTellerScriptID);
							cookies.set("storyTellerScriptID", storyTellerScriptID, { path: "/", maxAge: 3600 * 24 * 360 });
						}}
						type="text"
						style={{ width: 50, display: "inline", marginLeft: "5px", paddingTop: "0px" }}
					/>
				</Container>
			</Form.Group>

			<br />
			<Form.Group controlId="main.userID">
				<Container fluid>
					User ID:
					<Form.Control
						value={userID}
						onChange={(e) => {
							const userID = e.target.value;
							setUserID(userID);
							cookies.set("userID", userID, { path: "/", maxAge: 3600 * 24 * 360 });
						}}
						type="text"
						style={{ width: 100, display: "inline", marginLeft: "5px", paddingTop: "0px", marginRight: "20px" }}
					/>
					<Button className="btn btn-success" style={{ width: 200 }} variant="primary" onClick={onRefresh}>
						Refresh
					</Button>{" "}
				</Container>
			</Form.Group>

			<br />
			<Form.Group controlId="main.saveButtons">
				<Container fluid>
					<Button className="btn btn-success" style={{ width: 200 }} variant="primary" onClick={onShowLoadDialog}>
						Load
					</Button>{" "}
					Rewind T#:
					<Form.Control
						value={rewindChoices}
						onChange={(e) => {
							setrewindChoices(e.target.value);
						}}
						type="text"
						style={{ width: 50, display: "inline", marginLeft: "5px", paddingTop: "0px" }}
					/>{" "}
					Name:
					<Form.Control
						value={shallowSavedExperienceName}
						ref={saveNameRef}
						onChange={(e) => {
							setShallowSavedExperienceName(e.target.value);
						}}
						type="text"
						style={{ width: 500, display: "inline", marginLeft: "5px", paddingTop: "0px", marginRight: "20px" }}
					/>
					<Button className="btn btn-success" style={{ width: 200 }} variant="primary" onClick={onSave}>
						Save
					</Button>
					<br />
					built for Indra 0.6.7 - 13
				</Container>
			</Form.Group>
		</>
	);
}

export default withHotKeys(withCookies(App));
