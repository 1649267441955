import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { appConfig } from "./settings/Settings";

function getBackendEndpoint() {
	console.log("Preparing for endpoint assignment!");
	const protocol = window.location.protocol;
	if (protocol === "https:") {
		console.log("Using HTTP SECURE endpoint.");
		appConfig.BACKEND_URL = "https://api.pathofi.com/";
	} else {
		console.log("Using HTTP endpoint.");
		appConfig.BACKEND_URL = "http://ec2-13-49-145-121.eu-north-1.compute.amazonaws.com:3001/";
	}
}

//getBackendEndpoint();

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
